import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";
import Tooltip from "react-tooltip";

import LoadingIndicator from "components/LoadingIndicator";

import "./_style.scss";

const LinkButton = ({
  children,
  errorMessage = "",
  href = null,
  isBold = false,
  isDisabled = false,
  isFlashHighlight = false,
  isFullWidth = false,
  isIcon = false,
  isLabel = false,
  isLoading = false,
  isUppercase = false,
  loadingMessage = "",
  look,
  onClick,
  showTooltip = false,
  size,
  target = null,
  ...other
}) => {
  let Element = "span";
  if (href) {
    Element = "a";
  }
  if (onClick) {
    Element = "button";
  }

  let tooltipAttributes = null;

  if (showTooltip === true) {
    tooltipAttributes = {
      "data-tip": true,
      "data-multiline": true,
      "data-for": errorMessage ? "buttonErrorMessage" : "",
    };
  }

  const linkButton = (
    <Element
      data-test="link-button"
      {...other}
      href={href}
      onClick={(e) => { isDisabled || isLoading ? e.preventDefault() : (onClick ? onClick(e) : null); }}
      target={target}
      className={
        classNames(
          "link-button",
          other.className,
          {
            "--bold": isBold,
            "--disabled": isDisabled,
            "--full-width": isFullWidth,
            "--icon": isIcon,
            "--label": isLabel,
            "--loading": isLoading,
            "--uppercase": isUppercase,
            [`--${look}`]: look,
            [`--${size}`]: size,
            "--flash-highlight": isFlashHighlight,
          },
        )
      }
      {...tooltipAttributes}
    >
      {(isLoading === true)
        && (
        <>
          {loadingMessage}
          <LoadingIndicator
            size="1em"
            className="button__loading-indicator"
          />
        </>
        )}
      {(isLoading !== true) ? children : null }
    </Element>
  );

  if (showTooltip === true) {
    return (
      <>
        {linkButton}
        {showTooltip && errorMessage
          ? (
            <Tooltip id="buttonErrorMessage" type="info">
              {errorMessage}
            </Tooltip>
          )
          : null}
      </>
    );
  }

  return linkButton;
};

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  href: PropTypes.string,
  isBold: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFlashHighlight: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isIcon: PropTypes.bool,
  isLabel: PropTypes.bool,
  isLoading: PropTypes.bool,
  isUppercase: PropTypes.bool,
  loadingMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  look: PropTypes.oneOf([
    "plain",
    "plain-link",
    "primary",
    "primary-outline",
    "secondary",
    "secondary-outline",
    "tertiary",
    "tertiary-muted",
    "tertiary-outline",
    "icon-link",
    "icon-link-secondary",
    "payrequest",
    "paypal",
    "facebook",
  ]),
  onClick: PropTypes.func,
  showTooltip: PropTypes.bool,
  size: PropTypes.oneOf(["small", "normal", "large", "largev2", "huge"]),
  target: PropTypes.string,
};

export default LinkButton;
